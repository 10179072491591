@media (min-width: 768px) and (max-width: 992px)  {
    .pt-md-6,
    .py-md-6 {
      padding-top: 5rem !important;
    }

    .pr-md-6,
    .px-md-6 {
      padding-right: 5rem !important;
    }

    .pb-md-6,
    .py-md-6 {
      padding-bottom: 5rem !important;
    }

    .pl-md-6,
    .px-md-6 {
      padding-left: 5rem !important;
    }
}

.container {
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;
}

.container--narrow {
    max-width: 1240px
}

.container--very-narrow {
    max-width: 940px
}

.container--super-narrow {
    max-width: 540px
}

img {
    max-width: 100%
}

ul,ol,li {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    list-style: none
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0
}

button,html input[type=button],input[type=reset] {
    border: 0;
    cursor: pointer;
    -webkit-appearance: button
}

textarea input,textarea select {
    height: auto
}

hr {
    border-top: 1px solid #503921
}

label {
    margin: 0px 0px 10px 0px
}

input,.form-control,select,.form-select {
    background-color: #17120d;
    border: 1px solid #503921;
    outline: 0;
    color: #b79a7d;
    padding: .6rem 1rem;
    margin-bottom: 20px
}

input:active,input:hover,input:focus,.form-control:active,.form-control:hover,.form-control:focus,select:active,select:hover,select:focus,.form-select:active,.form-select:hover,.form-select:focus {
    border-color: #f90;
    background-color: #17120d;
    color: #f90;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

input::-webkit-input-placeholder, .form-control::-webkit-input-placeholder, select::-webkit-input-placeholder, .form-select::-webkit-input-placeholder {
    color: #b79a7d
}

input::-moz-placeholder, .form-control::-moz-placeholder, select::-moz-placeholder, .form-select::-moz-placeholder {
    color: #b79a7d
}

input:-ms-input-placeholder, .form-control:-ms-input-placeholder, select:-ms-input-placeholder, .form-select:-ms-input-placeholder {
    color: #b79a7d
}

input::-ms-input-placeholder, .form-control::-ms-input-placeholder, select::-ms-input-placeholder, .form-select::-ms-input-placeholder {
    color: #b79a7d
}

input::placeholder,.form-control::placeholder,select::placeholder,.form-select::placeholder {
    color: #b79a7d
}

input i,.form-control i,select i,.form-select i {
    color: #f90;
    margin-right: 10px
}

input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #17120d inset !important;
    box-shadow: 0 0 0 30px #17120d inset !important
}

input:-webkit-autofill {
    -webkit-text-fill-color: #f90 !important
}

.form-control:disabled,.form-control[readonly],.input-group-text {
    background-color: #17120d;
    border: 1px solid #17120d;
    outline: 0;
    color: #13d200;
    padding: .6rem 1rem;
    margin-bottom: 20px
}

.form-control:disabled i,.form-control[readonly] i,.input-group-text i {
    color: #f90
}

.form-select {
    height: auto
}

.form-check-label {
    margin-bottom: 0px;
    top: 2px;
    position: relative
}

.input-group {
    margin-bottom: 20px
}

.input-group input,.input-group .form-control,.input-group select,.input-group .form-select {
    margin-bottom: 0px
}

.input-group-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.input-group-flex input {
    width: 80%
}

.input-group-flex button {
    width: 20%;
    padding: 0px
}

.input-group button {
    background-color: #282018;
    -webkit-box-shadow: inset 0px 2px 15px #17120d;
    box-shadow: inset 0px 2px 15px #17120d;
    border: 1px solid #503921
}

.main-container {
    background-color: #100d09;
    position: relative;
    padding: 12px;
    background-image: url("./images/corner-tl.png"),url("./images/corner-tr.png"),url("./images/corner-br.png"),url("./images/corner-bl.png"),url("./images/frame-top.png"),url("./images/frame-right.png"),url("./images/frame-bottom.png"),url("./images/frame-left.png");
    background-size: auto;
    background-repeat: no-repeat,no-repeat,no-repeat,no-repeat,repeat-x,repeat-y,repeat-x,repeat-y;
    background-position: top left,top right,bottom right,bottom left,top center,right center,bottom center,left center;
    -webkit-box-shadow: 0px 0px 30px 10px rgba(0,0,0,.3);
    box-shadow: 0px 0px 30px 10px rgba(0,0,0,.3)
}

.main-container__inner {
    background-color: #100d09;
    position: relative;
    z-index: 2
}

.main-container::before {
    left: 80px;
    bottom: 80px;
    z-index: 1
}

.main-container::after {
    right: 80px;
    bottom: 80px;
    z-index: 1
}

.main-container::before,.main-container::after {
    content: "";
    height: 130px;
    width: 130px;
    position: absolute;
    background: rgba(19,210,0,.25);
    -webkit-filter: blur(125px);
    filter: blur(125px)
}

.main-container__glow--top {
    height: 130px;
    width: 260px;
    position: absolute;
    background: rgba(19,210,0,.25);
    -webkit-filter: blur(125px);
    filter: blur(125px);
    top: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 0
}

.main-container.no-glows::before,.main-container.no-glows::after {
    content: none;
    display: none
}

.content-box,.card {
    background-color: #17120d;
    background-image: url("./images/box-texture.jpg");
    background-size: auto;
    background-repeat: repeat;
    background-position: center;
    border: 1px solid #503921;
    border-radius: 0
}

.card-header {
    border-radius: 0;
    background-image: url("./images/maintitle-bg.jpg");
    background-size: cover;
    background-repeat: repeat-x;
    background-position: left bottom;
    height: 63px;
    text-shadow: 0px 2px 2px #000
}

.card-header:first-child {
    border-radius: 0
}

.nav-tabs .nav-item .nav-link {
    background-color: #17120d;
    color: #fff;
    border-radius: 0px
}

.nav-tabs .nav-item .nav-link.active {
    background-color: #211912;
    color: #f90;
    border-color: #503921 #503921 #f90
}

.nav-tabs .nav-item .nav-link:hover {
    border-color: #f90;
    background-color: #503921
}

.nav-tabs.nav-fill .nav-item .nav-link {
    width: 100%
}

.tab-content {
    padding: calc(30px / 2);
    background-color: #211912;
    border: 1px solid #503921;
    margin-bottom: calc(30px / 2)
}

::-webkit-scrollbar {
    width: 12px;
    background: #100d09
}

::-webkit-scrollbar-track {
    background: #100d09
}

::-webkit-scrollbar-thumb {
    background: #503921
}

.form-check-input {
    background-color: #211912;
    padding: .5rem
}

.form-check-input:checked {
    border-color: #f90;
    background-color: #f90
}

.form-check-input:focus {
    border-color: #f90;
    -webkit-box-shadow: 0 0 0 .2rem rgba(255,153,0,.25);
    box-shadow: 0 0 0 .2rem rgba(255,153,0,.25)
}

.form-check-input:checked~.form-check-input::before {
    border-color: #f90;
    background-color: #f90;
    -webkit-box-shadow: 0 0 0 .2rem rgba(255,153,0,.25);
    box-shadow: 0 0 0 .2rem rgba(255,153,0,.25)
}

.custom-control-label::before {
    background-color: #211912;
    border: #503921 solid 1px
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #f90;
    background-color: #f90;
    -webkit-box-shadow: 0 0 0 .2rem rgba(255,153,0,.25);
    box-shadow: 0 0 0 .2rem rgba(255,153,0,.25)
}

.pagination .page-item.active .page-link {
    color: #f90;
    background-color: #211912;
    border-color: #211912
}

.pagination .page-item .page-link {
    background-color: #17120d;
    border: 1px solid #211912;
    color: #13d200
}

.pagination .page-item .page-link:hover {
    color: #f90;
    background-color: #211912;
    border-color: #211912
}

.pagination .page-item .page-link:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(255,255,255,.2);
    box-shadow: 0 0 0 .2rem rgba(255,255,255,.2)
}

.table-overflow,.table-responsive {
    overflow-x: auto;
    margin-bottom: calc(30px / 2)
}

.table-overflow::-webkit-scrollbar,.table-responsive::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background: #100d09
}

table {
    border: 1px solid #17120d;
    margin-bottom: calc(30px / 6);
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #211912
}

table tr th,table tr td {
    padding: 10px 15px
}

@media(max-width: 768px) {
    table tr th,table tr td {
        padding:6px 10px
    }
}

table tr:nth-child(even) {
    background-color: #211912
}

table tr:hover {
    background-color: rgba(23,18,13,.2)
}

table thead {
    background-color: #17120d;
    text-transform: uppercase;
    color: #13d200;
    font-weight: 400
}

.modal .modal-dialog {
    position: relative
}

.modal .modal-dialog .btn-close {
    position: relative;
    height: 30px;
    width: 30px;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    font-size: 1.2em;
    z-index: 10;
    background-color: #282018;
    border: 1px solid #503921;
    -webkit-box-shadow: 0px 0px 30px rgba(80,57,33,.4),0px 2px 10px #17120d;
    box-shadow: 0px 0px 30px rgba(80,57,33,.4),0px 2px 10px #17120d
}

.modal .modal-dialog .btn-close:hover {
    -webkit-box-shadow: 0px 0px 15px #503921;
    box-shadow: 0px 0px 15px #503921
}

@media(max-width: 478px) {
    .modal .modal-dialog .btn-close {
        position:relative;
        top: 0;
        right: 0
    }
}

.modal .modal-dialog .modal-header {
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    border-bottom: 1px solid #503921
}

.modal .modal-dialog .modal-body {
    padding: calc(30px / 1)
}

.modal .modal-dialog .modal-content {
    background-color: #17120d;
    border: 1px solid #211912
}

.modal .modal-dialog .modal-title {
    margin-bottom: 0px !important
}

.modal-backdrop {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(23,18,13,.9)
}

.modal-backdrop.show {
    opacity: 1
}

@media(min-width: 576px) {
    .modal-dialog {
        max-width:540px
    }
}

@media(min-width: 920px) {
    .modal-dialog {
        max-width:860px
    }

    .modal-dialog.wide-width {
        max-width: 80%
    }
}

@media(min-width: 1340px) {
    .modal-dialog.wide-width {
        max-width:1480px
    }
}

@media(max-width: 992px) {
    .modal-dialog.wide-width {
        max-width:calc(100% - 30px)
    }
}

.video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%
}

.video-wrapper iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.alert-success {
    --bs-alert-color: #ffffff;
    --bs-alert-bg: #152609;
    --bs-alert-border-color: #2c580b
}

a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: all 350ms ease-in-out;
    transition: all 350ms ease-in-out;
    text-decoration-line: none;
}

.no-underline {
    text-decoration: none;
    text-decoration-line: none;
}

a:hover,a:focus,a:active {
    color: #f90;
    text-decoration: underline
}

p {
    margin: 0
}

.small {
    font-size: 12px
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
    line-height: 1.1;
    font-family: "Caudex","Cinzel",serif;
    font-size: 1.77rem;
    margin: 0 0 16px 0;
    color: #fff
}

h1,.h1 {
    font-size: 4.2rem
}

@media(max-width: 1200px) {
    h1,.h1 {
        font-size:3.1rem
    }
}

@media(max-width: 992px) {
    h1,.h1 {
        font-size:2rem
    }
}

@media(max-width: 768px) {
    h1,.h1 {
        font-size:1.5rem
    }
}

h2,.h2 {
    font-size: 3.1rem
}

@media(max-width: 1200px) {
    h2,.h2 {
        font-size:2.37rem
    }
}

@media(max-width: 992px) {
    h2,.h2 {
        font-size:2rem
    }
}

@media(max-width: 768px) {
    h2,.h2 {
        font-size:1.5rem
    }
}

h3,.h3 {
    font-size: 2.37rem
}

@media(max-width: 1200px) {
    h3,.h3 {
        font-size:1.77rem
    }
}

@media(max-width: 992px) {
    h3,.h3 {
        font-size:1.4rem
    }
}

@media(max-width: 768px) {
    h3,.h3 {
        font-size:1.2rem
    }
}

h4,.h4 {
    font-size: 1.77rem
}

@media(max-width: 1200px) {
    h4,.h4 {
        font-size:1.4rem
    }
}

@media(max-width: 992px) {
    h4,.h4 {
        font-size:1.2rem
    }
}

@media(max-width: 768px) {
    h4,.h4 {
        font-size:1rem
    }
}

h5,.h5 {
    font-size: 1.33rem
}

@media(max-width: 1200px) {
    h5,.h5 {
        font-size:1rem
    }
}

@media(max-width: 992px) {
    h5,.h5 {
        font-size:1rem
    }
}

@media(max-width: 768px) {
    h5,.h5 {
        font-size:1rem
    }
}

.text-primary {
    color: #f90 !important
}

.text-orange {
    color:  #f90 !important;
}

.text-white {
    color: #fff
}

.text-light-gray {
    color: #c7b5a3
}

.weight-600 {
    font-weight: 600 !important
}

.weight-700 {
    font-weight: 700 !important
}

.text-bold {
    font-weight: bold
}

.text-faded {
    color: #b79a7d
}

.text-shadow {
    text-shadow: 0px 10px 40px #000
}

.button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    border-radius: 0rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    background: -webkit-gradient(linear, left top, left bottom, from(#211912), color-stop(50.52%, #282018), color-stop(78.65%, #211912), to(#282018)),#282018;
    background: linear-gradient(180deg, #211912 0%, #282018 50.52%, #211912 78.65%, #282018 100%),#282018;
    border: 1px solid #503921;
    cursor: pointer;
    position: relative;
    z-index: 1;
    -webkit-transition: all .2s;
    transition: all .2s
}

.button:hover,.button:active,.button:focus {
    color: #f90;
    border-color: #f90
}

.button i:first-child {
    margin-right: .5rem
}

.button:last-child {
    margin-right: 0
}

.button:active {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
}

.button+.button {
    margin-left: 1em
}

.button--small {
    font-size: 14px
}

.button>svg {
    fill: currentcolor
}

.button.focus,.button:focus,button:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none
}

.top-nav-bar {
    border-bottom: 1px solid #503921;
    background-color: #17120d;
    background-image: url("./images/box-texture.jpg");
    background-size: auto;
    background-repeat: repeat;
    background-position: center;
    -webkit-box-shadow: 0px 0px 30px 10px rgba(0,0,0,.3);
    box-shadow: 0px 0px 30px 10px rgba(0,0,0,.3)
}

.navbar {
    width: 100%
}

.navbar .navbar-nav .nav-link {
    font-size: 1.33rem;
    color: #fff;
    font-family: "Caudex","Cinzel",serif;
    text-align: center
}

.navbar .navbar-nav .nav-link:hover {
    color: #f90
}

@media(max-width: 1200px) {
    .navbar .navbar-nav .nav-link {
        font-size:1rem
    }
}

.navbar .navbar-nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 3fr 1fr 1fr 1fr;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%
}

@media(max-width: 992px) {
    .navbar .navbar-nav {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media(max-width: 992px) {
    .navbar .navbar-collapse {
        position:absolute;
        background-color: #17120d;
        width: 100%;
        top: 144px;
        left: 0;
        right: 0;
        z-index: 9999
    }
}

.navbar .navbar-brand {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    margin: 0 auto
}

.navbar .navbar-brand:hover {
    -webkit-filter: brightness(120%);
    filter: brightness(120%)
}

.navbar .navbar-toggler {
    width: 40px;
    height: 40px;
    background-image: url("./images/social-icon.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0
}

.navbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("./images/svg/menu-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 24px;
    width: 24px
}

.navbar .navbar-toggler:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.footer {
    background-image: url("./images/frame-top.png");
    background-position: top center;
    background-repeat: repeat-x;
    padding: 12px 0px 0px 0px
}

.footer__inner {
    background-image: url("./images/footer-background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: 1px solid #503921;
    border-bottom: 1px solid #503921
}

.footer__divider {
    height: 1px;
    width: 100%;
    background-color: #282018
}

.footer__menu--divider {
    width: 7px;
    height: 16px;
    background-image: url("./images/footer-divider.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain
}

.footer__social {
    width: 40px;
    height: 40px;
    background-image: url("./images/social-icon.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease
}

.footer__social:hover {
    -webkit-filter: brightness(120%);
    filter: brightness(120%)
}

.footer__cc {
    background-color: #17120d;
    background-image: url("./images/box-texture.jpg");
    background-size: auto;
    background-repeat: repeat;
    background-position: center
}

.home-hero {
    z-index: 2;
    position: relative;
    padding-bottom: 6rem;
    margin-bottom: -4rem;
    background-image: url("./images/bg-orange.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center
}

.home-hero::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;
    background-image: url("./images/frame-top.png");
    background-position: top center;
    background-repeat: repeat-x;
    height: 6px;
    width: 100%
}

.hero-heading {
    font-size: 6rem
}

@media(max-width: 1200px) {
    .hero-heading {
        font-size:3.1rem
    }
}

@media(max-width: 992px) {
    .hero-heading {
        font-size:2rem
    }
}

@media(max-width: 768px) {
    .hero-heading {
        font-size:1.5rem
    }
}

.hero-desc {
    font-size: 1.33rem;
    color: #f5cfaa;
}

.hero-button {
    width: 280px;
    height: 74px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-box-shadow: 0px 10px 40px #000;
    box-shadow: 0px 10px 40px #000
}

.hero-button--play {
    background-image: url("./images/hero-button.jpg");
    -webkit-animation: PlayGlowing 3s infinite;
    animation: PlayGlowing 3s infinite
}

.hero-button--play:hover {
    color: #13d200;
    -webkit-box-shadow: 0px 10px 40px #000,0px 0px 60px rgba(19,210,0,.25);
    box-shadow: 0px 10px 40px #000,0px 0px 60px rgba(19,210,0,.25);
    -webkit-filter: brightness(120%);
    filter: brightness(120%)
}

.hero-button--play:focus {
    -webkit-transform: scale(0.94);
    transform: scale(0.94);
    color: #13d200
}

.hero-button--discord {
    background-image: url("./images/hero-button-discord.jpg")
}

.hero-button--discord:hover {
    color: #5966f3;
    -webkit-box-shadow: 0px 10px 40px #000,0px 0px 60px rgba(89,102,243,.25);
    box-shadow: 0px 10px 40px #000,0px 0px 60px rgba(89,102,243,.25)
}

.hero-button--discord:focus {
    -webkit-transform: scale(0.94);
    transform: scale(0.94);
    color: #5966f3
}

@-webkit-keyframes PlayGlowing {
    0% {
        -webkit-box-shadow: 0px 10px 40px #000,0px 0px 10px rgba(19,210,0,.25);
        box-shadow: 0px 10px 40px #000,0px 0px 10px rgba(19,210,0,.25)
    }

    50% {
        -webkit-box-shadow: 0px 10px 20px rgba(0,0,0,.35),0px 0px 60px rgba(19,210,0,.35);
        box-shadow: 0px 10px 20px rgba(0,0,0,.35),0px 0px 60px rgba(19,210,0,.35)
    }

    100% {
        -webkit-box-shadow: 0px 10px 40px #000,0px 0px 10px rgba(19,210,0,.25);
        box-shadow: 0px 10px 40px #000,0px 0px 10px rgba(19,210,0,.25)
    }
}

@keyframes PlayGlowing {
    0% {
        -webkit-box-shadow: 0px 10px 40px #000,0px 0px 10px rgba(19,210,0,.25);
        box-shadow: 0px 10px 40px #000,0px 0px 10px rgba(19,210,0,.25)
    }

    50% {
        -webkit-box-shadow: 0px 10px 20px rgba(0,0,0,.35),0px 0px 60px rgba(19,210,0,.35);
        box-shadow: 0px 10px 20px rgba(0,0,0,.35),0px 0px 60px rgba(19,210,0,.35)
    }

    100% {
        -webkit-box-shadow: 0px 10px 40px #000,0px 0px 10px rgba(19,210,0,.25);
        box-shadow: 0px 10px 40px #000,0px 0px 10px rgba(19,210,0,.25)
    }
}

.home-grid-layout {
    display: grid;
    grid-template-columns: auto minmax(auto, 480px);
    gap: 6px
}

@media(max-width: 1400px) {
    .home-grid-layout {
        grid-template-columns:1.5fr 1fr
    }
}

@media(max-width: 992px) {
    .home-grid-layout {
        grid-template-columns:1fr
    }
}

.home-grid-layout__column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 6px
}

.welcome-section {
    background-image: url("./images/welcome-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center
}

.welcome-section p {
    max-width: 640px
}

.news-list__title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.news-list li {
    border-bottom: 1px solid #282018
}

.news-list li:last-of-type {
    border: 0
}

.media-section .carousel-controls {
    position: absolute
}

.media-section .carousel-control-next {
    right: -2rem
}

.media-section .carousel-control-prev {
    left: -2rem
}

@media(max-width: 768px) {
    .media-section .carousel-control-next {
        right:-1.5rem
    }

    .media-section .carousel-control-prev {
        left: -1.5rem
    }
}

.media-section .carousel-control-next-icon,.media-section .carousel-control-prev-icon {
    width: 48px;
    height: 48px
}

.media-section .carousel-control-prev-icon {
    background-image: url("./images/slider-arrow.png")
}

.media-section .carousel-control-next-icon {
    background-image: url("./images/slider-arrow.png");
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
}

.media-section .carousel-inner .carousel-item.active,.media-section .carousel-inner .carousel-item-next,.media-section .carousel-inner .carousel-item-prev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media(max-width: 767px) {
    .media-section .carousel-inner .carousel-item>div {
        display:none
    }

    .media-section .carousel-inner .carousel-item>div:first-child {
        display: block
    }

    .media-section .carousel-inner .carousel-item.active {
        display: block
    }
}

@media(min-width: 768px) {
    .media-section .carousel-inner .carousel-item-end.active,.media-section .carousel-inner .carousel-item-next {
        -webkit-transform:translateX(33.3333%);
        transform: translateX(33.3333%)
    }

    .media-section .carousel-inner .carousel-item-start.active,.media-section .carousel-inner .carousel-item-prev {
        -webkit-transform: translateX(-33.3333%);
        transform: translateX(-33.3333%)
    }
}

.media-section .carousel-inner .carousel-item-end,.media-section .carousel-inner .carousel-item-start {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.media-container {
    background-color: #100d09;
    position: relative;
    padding: 12px;
    background-image: url("./images/corner-tl.png"),url("./images/corner-tr.png"),url("./images/corner-br.png"),url("./images/corner-bl.png"),url("./images/frame-top.png"),url("./images/frame-right.png"),url("./images/frame-bottom.png"),url("./images/frame-left.png");
    background-size: auto;
    background-repeat: no-repeat,no-repeat,no-repeat,no-repeat,repeat-x,repeat-y,repeat-x,repeat-y;
    background-position: top left,top right,bottom right,bottom left,top center,right center,bottom center,left center;
    -webkit-box-shadow: 0px 0px 30px 10px rgba(0,0,0,.3);
    box-shadow: 0px 0px 30px 10px rgba(0,0,0,.3)
}

.ql {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 480px;
    width: 100%
}

.ql .ql-btn {
    -webkit-transition: all 400ms ease-in;
    transition: all 400ms ease-in
}

.ql .ql-btn:hover {
    -webkit-filter: brightness(120%);
    filter: brightness(120%)
}

.ql__discord {
    background-image: url("./images/discord-bg.jpg")
}

.ql__discord .ql-btn:hover {
    -webkit-box-shadow: 0px 0px 30px rgba(89,102,243,.2);
    box-shadow: 0px 0px 30px rgba(89,102,243,.2)
}

.ql__download {
    background-image: url("./images/download-bg.jpg")
}

.ql__download .ql-btn:hover {
    -webkit-box-shadow: 0px 0px 30px rgba(19,210,0,.2);
    box-shadow: 0px 0px 30px rgba(19,210,0,.2)
}

.ql__vote {
    background-image: url("./images/vote-bg.jpg")
}

.ql__vote .ql-btn:hover {
    -webkit-box-shadow: 0px 0px 30px rgba(255,153,0,.2);
    box-shadow: 0px 0px 30px rgba(255,153,0,.2)
}

.store-cat-btn {
    background-color: #211912;
    border: 1px solid #17120d;
    color: #fff;
    -webkit-transition: all 350ms ease-in;
    transition: all 350ms ease-in
}

.store-cat-btn+.store-cat-btn {
    margin-left: 0
}

.store-cat-btn:hover {
    color: #fff;
    margin-left: 0rem;
    background-color: #17120d
}

.store-cat-btn.active {
    color: #f90;
    background-color: #211912;
    border-color: #f90
}

.store-cat-btn img {
    margin-right: 10px
}

.product-block {
    border-radius: 0px;
    background-color: #17120d;
    border: 1px solid #211912
}

.product-block.new-product {
    border-color: green
}

.product-block.sale-product {
    border-color: maroon
}

.product-header-block {
    background-color: #211912
}

.product-image {
    width: 80px;
    height: 80px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    position: relative;
    margin-left: auto;
    border-radius: 0px;
    background-color: #17120d
}

.product-image img {
    -o-object-fit: contain;
    object-fit: contain;
    height: 64px
}

.product-badge {
    position: absolute;
    top: 0px;
    left: -50px;
    display: inline-block;
    color: #fff;
    background-color: #17120d;
    padding: .3rem .5rem;
    text-transform: uppercase;
    font-size: .6rem;
    line-height: 1;
    -webkit-box-shadow: 0px 10px 25px 0px rgba(0,0,0,.2);
    box-shadow: 0px 10px 25px 0px rgba(0,0,0,.2)
}

.product-badge.new {
    background-color: green
}

.product-badge.sale {
    background-color: maroon
}

.product-badge+.product-badge {
    margin-top: 30px
}

.product-name {
    font-weight: 600
}

.product-price.new-price {
    color: #f90
}

.product-price.old-price {
    text-decoration: line-through
}

.product-price+.product-price {
    margin-left: 10px
}

.product-desc {
    color: #b79a7d;
    font-size: 14px
}

.product-quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.product-quantity input {
    background-color: #211912;
    border: 1px solid #17120d;
    color: #fff;
    text-align: center
}

.product-quantity input:focus,.product-quantity input:active {
    background-color: #17120d;
    color: #f90
}

.product-quantity button {
    background-color: #211912;
    border: 1px solid #17120d;
    outline: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-quantity button i {
    color: #fff;
    font-size: .5em
}

.product-quantity button:hover {
    background-color: #17120d
}

.product-quantity button:hover i {
    color: #f90
}

.add-cart i {
    margin-right: 10px
}

.add-cart.small {
    display: inline-block;
    width: auto
}

.add-cart.small i {
    margin-right: 0
}

.page-link {
    background-color: #17120d;
    border-color: #17120d;
    color: #fff;
    margin: 0px 1px
}

.page-link:hover,.page-link:focus {
    background-color: #17120d;
    border-color: #17120d;
    color: #f90;
    -webkit-box-shadow: 0 0 0 .2rem rgba(255,153,0,.25);
    box-shadow: 0 0 0 .2rem rgba(255,153,0,.25)
}

.page-item.active .page-link {
    color: #f90;
    background-color: #17120d;
    border-color: #f90
}

.deals-row {
    border-bottom: 1px solid #17120d;
    padding-bottom: 1rem;
    margin-bottom: 1rem
}

.deals-row:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0
}

.remove-cart {
    margin-right: 5px;
    font-size: .8rem
}

.cart-list li p {
    margin-bottom: 0
}

.cart-row {
    border-radius: 0px;
    background-color: #211912
}

@media(max-width: 1400px) {
    .checkout-container .cart-row {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.top-donor-list li p {
    margin-bottom: 0
}

.top-donor-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: .5rem;
    border-radius: 0px;
    background-color: #211912;
    border: 1px solid #17120d;
    margin-bottom: .5rem
}

@media(max-width: 1200px) {
    .top-donor-row {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media(max-width: 992px) {
    .top-donor-row {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.top-donor-rank-badge {
    padding: .3rem .4rem;
    padding-bottom: .4rem;
    background-color: #17120d;
    margin-right: .5rem;
    line-height: 1;
    border-radius: 0px
}

.top-donor-rank-badge.gold {
    color: #ffd900;
    background-color: #342f14
}

.top-donor-rank-badge.silver {
    color: #bfbfbf;
    background-color: #454545
}

.top-donor-rank-badge.bronze {
    color: #cea083;
    background-color: #3f3027
}

.top-donor-amount {
    color: #f90;
    font-weight: bold
}

.top-donor-amount.gold {
    color: #ffd900
}

.top-donor-amount.silver {
    color: #bfbfbf
}

.top-donor-amount.bronze {
    color: #cea083
}

.checkout-container {
    max-width: 60%;
    margin: 0 auto
}

@media(max-width: 1400px) {
    .checkout-container {
        max-width:100%
    }
}

.cart-product-img {
    height: 32px;
    width: 32px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    margin-right: 10px
}

.checkout-discount-code {
    padding: .3rem .5rem;
    background-color: #21692d;
    color: #fff;
    border-radius: 0px
}

.checkout-discount-code i {
    color: #fff;
    margin-right: 10px
}

.payment-methods img {
    height: auto;
    max-height: 70px;
    width: 100%;
    padding: 20px 10px;
    -o-object-fit: contain;
    object-fit: contain
}

@media(max-width: 768px) {
    .payment-methods img {
        max-height:60px
    }
}

.payment-methods label {
    display: inline-block;
    position: relative;
    background-color: #17120d;
    text-align: center
}

.payment-methods [type=radio] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%
}

.payment-methods [type=radio]+img {
    cursor: pointer
}

.payment-methods [type=radio]:checked+img {
    outline: 2px solid #f90
}

.payment-methods [type=radio]:checked+img.bitcoin {
    outline: 2px solid #ffa600
}

.payment-methods [type=radio]:checked+img.paypal {
    outline: 2px solid #019ddf
}

.payment-methods [type=radio]:checked+img.stripe {
    outline: 2px solid #fff
}

.featured-product__background {
    background-image: url("./images/featured-product-bg.jpg");
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat
}

.featured-product__image {
    background-color: #211912;
    height: 120px;
    width: 120px
}

.featured-product__image img {
    -o-object-fit: contain;
    object-fit: contain;
    height: 100%;
    max-width: 100%;
    -webkit-filter: drop-shadow(5px 5px 30px rgba(255, 223, 39, 0.5));
    filter: drop-shadow(5px 5px 30px rgba(255, 223, 39, 0.5))
}

.custom-deals__background {
    background-image: url("./images/custom-deals-bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.custom-deals__image {
    max-width: 80%;
    margin: auto
}

.recommended-box {
    padding: 3px 7px;
    background-color: #152609;
    color: white;
    border: 0.5px #2c580b solid;
    border-radius: 0.375rem;
}